export const srConfig = {
  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: "bottom",
    distance: "20px",
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    mobile: true,
    reset: false,
    useDelay: "always",
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};

export const aboutPara = `
  Hi there! I'm Tiffany, a Product Designer based in Singapore with an unhealthy obsession with typefaces and a software engineering background.
  <br />
  <br />
  I'm currently on the product design team at <b>Open Government Products</b>, helping build impactful tech for public good.
  <br />
  <br />
  I also recently graduated from the National University of Singapore, where I majored in <b>Computer Science</b>. Previously, I've designed payment and shopping experiences for 32 million transacting users across 8 countries in Southeast Asia at <b>Grab</b>.
  <br />
  <br />
  My design process is inspired by my background in <b>both engineering
  and design</b>, I love connecting the dots between business goals,
  technical constraints and user satisfaction, and talking to engineers.
</p>
`;
